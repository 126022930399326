import { areaListTypes } from "./areaList.types";

const INITIAL_STATE = {
    error: "",
    areaData: "",
    loading: true,
};

const areaListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case areaListTypes.AREA_LIST_ACTION_START:
            return {
                ...state,
                loading: true,
            };
        case areaListTypes.AREA_LIST_GET_SUCCESS:
            return {
                ...state,
                areaData: action.payload,
                error: "",
            };
        case areaListTypes.AREA_LIST_GET_FAILED:
            return {
                ...state,
                error: action.payload,
            };
        case areaListTypes.AREA_LIST_ACTION_END:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
export default areaListReducer;
