import { viewMorePropertyTypes } from "./viewMoreProperty.types";
import { token } from '../../../../Helper/apiToken';
import AxiosWithOutAuthInstance from "../../../../config/api/withoutauth.axios";
export const viewMorePropertyAction = (view, purpose, conststatus, location, productType, beds, bath, minsqft, maxsqft, minprice, maxprice, cities, tags, currentPage) => async (dispatch) => {
    dispatch({
        type: viewMorePropertyTypes.VIEW_MORE_PROPERTY_ACTION_START,
    });
    await AxiosWithOutAuthInstance.get(`/web-view-more-property?view=${view}&purpose=${purpose}&status=${conststatus}&location=${location}&type=${productType}&beds=${beds}&baths=${bath}&minsqft=${minsqft}&maxsqft=${maxsqft}&minprice=${minprice}&maxprice=${maxprice}&city=${cities}&tags=${tags}&page=${currentPage}&api_key=${token}`).toPromise().then(
        (res) => {
            dispatch({
                type: viewMorePropertyTypes.VIEW_MORE_PROPERTY_GET_SUCCESS,
                payload: res?.data,
            });
            dispatch({
                type: viewMorePropertyTypes.VIEW_MORE_PROPERTY_ACTION_END,
            });
        },
        (error) => {
            dispatch({
                type: viewMorePropertyTypes.VIEW_MORE_PROPERTY_GET_FAILED,
                payload: error,
            });
            dispatch({
                type: viewMorePropertyTypes.VIEW_MORE_PROPERTY_ACTION_END,
            });
        }
    );
};
