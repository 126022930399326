import { viewMorePropertyTypes } from "./viewMoreProperty.types";

const INITIAL_STATE = {
    error: "",
    viewMorePropertyData: "",
    loading: true,
};

const viewMorePropertyReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case viewMorePropertyTypes.VIEW_MORE_PROPERTY_ACTION_START:
            return {
                ...state,
                loading: true,
            };
        case viewMorePropertyTypes.VIEW_MORE_PROPERTY_GET_SUCCESS:
            return {
                ...state,
                viewMorePropertyData: action.payload,
                error: "",
            };
        case viewMorePropertyTypes.VIEW_MORE_PROPERTY_GET_FAILED:
            return {
                ...state,
                error: action.payload,
            };
        case viewMorePropertyTypes.VIEW_MORE_PROPERTY_ACTION_END:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
export default viewMorePropertyReducer;
